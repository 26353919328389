import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { get } from "../../api/axios";
import { Pagination } from "antd";
import SearchBar from "../../utils/SearchBar";

const PropertySales = () => {
  const columns = [
    {
      title: (
        <>
          كود الوحدة <br />
        </>
      ),
      render: ({ property_code }) => <span>{property_code}</span>,
    },
    {
      title: " بيانات المضيف ",
      dataIndex: "owner",
      render: ({ name, phone, email }) => (
        <span>
          الاسم:
          <br />
          {name || "-"}
          <br />
          الجوال:
          <br />
          {phone || "-"}
          <br />
          البريد الإلكتروني:
          <br />
          {email || "-"}
        </span>
      ),
    },
    {
      title: (
        <>
          المدفوع من الضيوف <br />
          رسوم الخدمة <br />
          ضريبة الرسوم <br />
          الرسوم شامل الضريبة <br />
          مبيعات الوحدة <br />
            العمولة <br />

          ضريبة العمولة <br />
          العمولة شامل الضريبة <br />
          صافي مبيعات الوحدة <br />
        </>
      ),
      // dataIndex: "prices",
      render: ({
        total_price,
        service_amount,
        service_tax,
        commission_amount,
        total_price_without_service,
        owner_total_payout,
        total_commission,
        total_service,
        tax_amount
      }) => (
        <span>
          {total_price}
          <br />
          {service_amount}
          <br />
          {service_tax}
          <br />
          {total_service}
          <br />
          {total_price_without_service}
          <br />
          {commission_amount}
          <br />
          {tax_amount} <br />

          {total_commission} <br />
          {owner_total_payout}
        </span>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
 
  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`statistics/property-sales`).then((res) => {
      setdata(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, []);
  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`statistics/property-sales?page=${pageNumber}`).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
       
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertySales;
