import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { del, get, post, put } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination, Switch } from "antd";
import {
  InstagramOutlined,
  TwitterOutlined,
  FacebookFilled,
} from "@ant-design/icons";
import { checkPermission } from "../auth/ProtectedRoutes";
import SearchBar from "../../utils/SearchBar";

const Employees = () => {
  const columns = [
    {
      title: "الرقم",
      dataIndex: "id",
    },
    {
      title: (
        <>
          الاسم
          <br />
          الجوال
        </>
      ),
      render: ({ name, phone }) => (
        <>
          {name} <br />
          {phone}
        </>
      ),
    },
    {
      title: (
        <>
          البريد الإلكتروني
          <br />
          العنوان
          <br />
          تاريخ الاضافة
        </>
      ),
      render: ({ created_at, email, address }) => (
        <>
          {email} <br />
          {address}
          <br />
          {created_at}
        </>
      ),
    },
    {
      title: (
        <>
          الإقامة
          <br />
          الجنسية
        </>
      ),
      render: ({ residence_type, nationality_id }) => (
        <>
          {residence_type} <br />
          {nationality_id}
        </>
      ),
    },
    {
      title: (
        <>
          رقم الاثبات
          <br />
          تاريخ الميلاد
        </>
      ),
      render: ({ id_number, dob }) => (
        <>
          {id_number} <br />
          {dob}
        </>
      ),
    },
    {
      title: (
        <>
          الجنس
          <br />
          الحالة الاجتماعية
        </>
      ),
      render: ({ gender, marital_status }) => (
        <>
          {gender} <br />
          {marital_status}
        </>
      ),
    },
    {
      title: (
        <>
          التأمينات الاجتماعية
          <br />
          خصم التأمينات
        </>
      ),
      render: ({ social_insurance, insurance_discount }) => (
        <>
          {social_insurance} <br />
          {insurance_discount}
        </>
      ),
    },
    {
      title: (
        <>
          المؤهل
          <br />
          التخصص
        </>
      ),
      render: ({ qualification, specialization }) => (
        <>
          {qualification} <br />
          {specialization}
        </>
      ),
    },
    {
      title: (
        <>
          مصدر الشهادة
          <br />
          دولة المؤهل
        </>
      ),
      render: ({ source_of_certificate, country_of_certificate }) => (
        <>
          {source_of_certificate||"-"}
          <br />
          {country_of_certificate.name_ar||"-"}
        </>
      ),
    },
    {
      title: (
        <>
          تاريخ التخرج <br />
          التقدير
        </>
      ),
      render: ({ graduation_date, rate }) => (
        <>
          {graduation_date} <br /> {rate?.name}{" "}
        </>
      ),
    },
    {
      title: (
        <>
          رقم الموظف
          <br />
          الأذونات
        </>
      ),
      render: ({ code, role }) => (
        <>
          {code} <br />
          {role}
        </>
      ),
    },
    {
      title: (
        <>
          تاريخ التعاقد
          <br />
          تاريخ انتهاء التعاقد
        </>
      ),
      render: ({ contact_start_date, contact_end_date }) => (
        <>
          {contact_start_date} <br />
          {contact_end_date}
        </>
      ),
      width: "130px",
    },
    {
      title: (
        <>
          المسمى الوظيفي
          <br />
          مصدر التوظيف
        </>
      ),
      render: ({ job_title, employment_source }) => (
        <>
          {job_title} <br />
          {employment_source}
        </>
      ),
    },
    {
      title: "الراتب ",
      dataIndex: "salary",
    },
    {
      title: "الانستغرام",
      dataIndex: "instagram_url",
      render: (instagram_url) => (
        <a
          className="color-inherit text-decoration-none py-1 px-2 d-block"
          target="_blank"
          rel="noreferrer"
          href={instagram_url}
        >
          <InstagramOutlined style={{ fontSize: "20px" }} />
        </a>
      ),
    },
    {
      title: "تويتر",
      dataIndex: "twitter_url",
      render: (twitter_url) => (
        <a
          className="color-inherit text-decoration-none py-1 px-2 d-block"
          target="_blank"
          rel="noreferrer"
          href={twitter_url}
        >
          <TwitterOutlined style={{ fontSize: "20px" }} />
        </a>
      ),
    },
    {
      title: "فيسبوك",
      dataIndex: "facebook_url",
      render: (facebook_url) => (
        <a
          className="color-inherit text-decoration-none py-1 px-2 d-block"
          target="_blank"
          rel="noreferrer"
          href={facebook_url}
        >
          <FacebookFilled style={{ fontSize: "20px" }} />
        </a>
      ),
    },
  ];

  if (
    checkPermission("update-employee") ||
    checkPermission("delete-employee")
  ) {
    columns.push(
      {
        title: "تعديل",
        dataIndex: "id",
        render: (id) => (
          <button className="btn btn-outline-warning btn-sm p-0">
            <Link
              className="color-inherit text-decoration-none py-1 px-2 d-block"
              to={`/employees/edit/${id}`}
            >
              تعديل
            </Link>
          </button>
        ),
      },
      {
        title: "حذف",
        dataIndex: "id",
        render: (id) => (
          <Popconfirm
            title="حذف العنصر"
            description="هل أنت متأكد من حذف هذه العنصر؟"
            onConfirm={() => confirm(id)}
            onCancel={cancel}
            okText="نعم"
            cancelText="لا"
          >
            <Button danger>حذف</Button>
          </Popconfirm>
        ),
      }
    );
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [total, setTotal] = useState(1);
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  useEffect(() => {
    get(`employees?search=${searchQuery}`).then((res) => {
      setData(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);
  const handleDelet = (id) => {
    del(`employees/${id}`);
    let dateFilter = data.filter((item) => item.id !== id);
    setData(dateFilter);
  };

  const confirm = (id) => {
    console.log(id);
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = (e) => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`employees?page=${pageNumber}`).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-employee") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
              className="text-decoration-none d-block h-100"
              to="/employees/add"
            >
              <Button type="primary" block>
                {" "}
                إضافة{" "}
              </Button>
            </Link>
          </div>
        )}
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 1850,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
