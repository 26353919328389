import React, { useEffect, useState } from "react";
import { Divider, Spin } from "antd";
import { get } from "../../api/axios";
import StatisticItem from "./StatisticItem";

const MakanStatistics = () => {
  const [data, setData] = useState(null);
  const [rating, setRating] = useState(null);
  const [views, setViews] = useState(null);
  const [sales, setSales] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllStatistics = async () => {
      try {
        const [dataResponse, viewsResponse, ratingResponse, salesResponse] =
          await Promise.all([
            get("get-makan-statistics"),
            get("/statistics/views"),
            get("/statistics/rating"),
            get("/statistics/sales"),
          ]);

        setData(dataResponse?.data);
        setViews(viewsResponse?.data);
        setRating(ratingResponse?.data);
        setSales(salesResponse?.data);
      } catch (err) {
        console.error("Error fetching statistics:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllStatistics();
  }, []);
  if (loading) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }
  if (!data) {
    return <div className="text-center">No data available.</div>;
  }

  return (
      <div className="makan-statistics">
        <h3 className="text-main">أحصائيات مكان</h3>

        <Divider orientation="left">العقارات</Divider>
        <div className="row mt-2">
          {data?.properties?.map((item, index) => (
              <StatisticItem key={index} label={item.name} value={item.count}/>
          ))}
        </div>

        <Divider orientation="left">المستخدمين</Divider>
        <div className="row mt-2">
          <StatisticItem label="عدد المستخدمين" value={data.users?.all?.all}/>
          <StatisticItem label="عدد الضيوف" value={data.users?.guests?.all}/>
          <StatisticItem label="عدد المضيفين" value={data.users?.owners?.all}/>
          <StatisticItem
              label="عدد المستخدمين (Web)"
              value={data.users?.all?.web}
          />
          <StatisticItem
              label="عدد الضيوف (Web)"
              value={data.users?.guests?.web}
          />
          <StatisticItem
              label="عدد المضيفيين (Web)"
              value={data.users?.owners?.web}
          />
          <StatisticItem
              label="عدد المستخدمين (iOS)"
              value={data.users?.all?.ios}
          />
          <StatisticItem
              label="عدد الضيوف (iOS)"
              value={data.users?.guests?.ios}
          />
          <StatisticItem
              label="عدد المضيفيين (iOS)"
              value={data.users?.owners?.ios}
          />
          <StatisticItem
              label="عدد المستخدمين (Android)"
              value={data.users?.all?.android}
          />
          <StatisticItem
              label="عدد الضيوف (Android)"
              value={data.users?.guests?.android}
          />
          <StatisticItem
              label="عدد المضيفيين (Android)"
              value={data.users?.owners?.android}
          />
        </div>

        <Divider orientation="left">الحجوزات</Divider>
        <div className="row mt-2">
          <StatisticItem label="عدد الحجوزات" value={data.reservations?.total}/>
          <StatisticItem
              label="عدد الحجوزات المؤكدة"
              value={data.reservations?.status?.confirmed}
          />
          <StatisticItem
              label="عدد الحجوزات الملغية"
              value={data.reservations?.status?.canceled}
          />
          <StatisticItem
              label="عدد الحجوزات (Web)"
              value={data.reservations?.platforms?.web}
          />
          <StatisticItem
              label="عدد الحجوزات (iOS)"
              value={data.reservations?.platforms?.ios}
          />
          <StatisticItem
              label="عدد الحجوزات (Android)"
              value={data.reservations?.platforms?.android}
          />

          <StatisticItem
              label="عدد الحجوزات (المضيف)"
              value={data.reservations?.source?.owner}
          />
        </div>

        <Divider orientation="left">التقييمات</Divider>
        <div className="row mt-2">
          <StatisticItem
              label="نظافة المكان"
              value={rating?.rate_clean}
          />
          <StatisticItem label="عدد التقييمات  " value={rating.rate_count}/>
          <StatisticItem label="حالة المكان  " value={rating.rate_new}/>
          <StatisticItem
              label="عدد تعليقات الضيوف "
              value={rating.rate_note_count}
          />

          <StatisticItem label="خدمة المضيف" value={rating.rate_service}/>
          <StatisticItem
              label="عدد ردود المضيفين على التعليقات "
              value={rating.reply_note_count}
          />

          <StatisticItem label="تطابق المواصفات" value={rating.rate_matching}/>
          <StatisticItem
              label="معدل عدد ردود المضيفين لعدد التعليقات "
              value={rating.replies_rate_avg}
          />

          <StatisticItem label="الجودة مقابل السعر" value={rating.rate_pricing}/>
          <StatisticItem
              label="معدل عدد التقييمات لعدد الحجوزات"
              value={rating.reservations_rate_avg}
          />
          <StatisticItem label="التقييم العام" value={rating.rate_total_rating}/>
          
        </div>

        <Divider orientation="left">المشاهدات</Divider>
        <div className="row mt-2">
          <StatisticItem label="عدد النقرات " value={views.total_clicks}/>
          <StatisticItem label="عدد المشاهدات " value={views.total_views}/>
          <StatisticItem label="عدد الزيارات " value={views.total_views}/>
          <StatisticItem
              label="معدل تحول المشاهدات إلى نقرات "
              value={views.view_to_click_rate}
          />
          <StatisticItem
              label="معدل عدد تحول النقرات إلى حجز مؤكد "
              value={views.click_to_reservation_rate}
          />
        </div>

        <Divider orientation="left">المبيعات</Divider>
        <br/>
        <h6>المبيعات الكلية </h6>
        <div className="row mt-2">
          <StatisticItem
              label="المجموع المدفوع من الضيوف"
              value={(sales.total?.total_price || 0) }
          />
          <StatisticItem
              label="مبيعات المضيفين"
              value={(sales.total?.sub_total || 0) }
          />
          <StatisticItem
              label="صافي مبيعات المضيفين"
              value={(sales.total?.owner_total_payout || 0) }
          />

          <StatisticItem
              label="الرسوم شامل الضريبة"
              value={(sales.total?.total_service || 0) }
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (الرسوم)"
              value={(sales.total?.service_tax || 0) }
          />
          <StatisticItem
              label="صافي مبيعات مكان (الرسوم)"
              value={(sales.total?.service_amount || 0) }
          />

          <StatisticItem
              label="العمولة شامل الضريبة"
              value={(sales.total?.total_commission || 0) }
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (العمولة)"
              value={((sales.total?.tax_amount || 0)) }
          />
          <StatisticItem
              label="صافي مبيعات مكان (العمولة)"
              value={(sales.total?.commission_amount || 0)}
          />

          <StatisticItem
              label="الرسوم والعمولة شامل الضريبة"
              value={(sales.total?.total_commission_and_service || 0) }
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (الرسوم والعمولة)"
              value={(sales.total?.tax_amount_and_service_tax || 0) }
          />
          <StatisticItem
              label="صافي مبيعات مكان (الرسوم والعمولة)"
              value={(sales.total?.commission_amount_and_service_amount || 0) }
          />

        </div>

        <h6> المبيعات المستردة </h6>
        <div className="row mt-2">
          <StatisticItem
              label="المجموع المدفوع من الضيوف"
              value={(sales.canceled?.total_price || 0).toFixed(1)}
          />
          <StatisticItem
              label="مبيعات المضيفين"
              value={(sales.canceled?.sub_total || 0).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات المضيفين"
              value={(sales.canceled?.owner_total_payout || 0).toFixed(1)}
          />

          <StatisticItem
              label="الرسوم شامل الضريبة"
              value={(sales.canceled?.total_service || 0).toFixed(1)}
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (الرسوم)"
              value={(sales.canceled?.service_tax || 0).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات مكان (الرسوم)"
              value={(sales.canceled?.service_amount || 0).toFixed(1)}
          />

          <StatisticItem
              label="العمولة شامل الضريبة"
              value={(sales.canceled?.total_commission || 0).toFixed(1)}
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (العمولة)"
              value={((sales.canceled?.tax_amount || 0)).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات مكان (العمولة)"
              value={(sales.canceled?.commission_amount || 0).toFixed(1)}
          />

          <StatisticItem
              label="الرسوم والعمولة شامل الضريبة"
              value={(sales.canceled?.total_commission_and_service || 0).toFixed(1)}
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (الرسوم والعمولة)"
              value={(sales.canceled?.tax_amount_and_service_tax || 0).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات مكان (الرسوم والعمولة)"
              value={(sales.canceled?.commission_amount_and_service_amount || 0).toFixed(1)}
          />

        </div>

        <h6>صافي المبيعات </h6>
        <div className="row mt-2">
          <StatisticItem
              label="المجموع المدفوع من الضيوف"
              value={(sales.paid?.total_price || 0).toFixed(1)}
          />
          <StatisticItem
              label="مبيعات المضيفين"
              value={(sales.paid?.sub_total || 0).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات المضيفين"
              value={(sales.paid?.owner_total_payout || 0).toFixed(1)}
          />

          <StatisticItem
              label="الرسوم شامل الضريبة"
              value={(sales.paid?.total_service || 0).toFixed(1)}
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (الرسوم)"
              value={(sales.paid?.service_tax || 0).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات مكان (الرسوم)"
              value={(sales.paid?.service_amount || 0).toFixed(1)}
          />

          <StatisticItem
              label="العمولة شامل الضريبة"
              value={(sales.paid?.total_commission || 0).toFixed(1)}
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (العمولة)"
              value={((sales.paid?.tax_amount || 0)).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات مكان (العمولة)"
              value={(sales.paid?.commission_amount || 0).toFixed(1)}
          />

          <StatisticItem
              label="الرسوم والعمولة شامل الضريبة"
              value={(sales.paid?.total_commission_and_service || 0).toFixed(1)}
          />
          <StatisticItem
              label="ضريبة القيمة المضافة (الرسوم والعمولة)"
              value={(sales.paid?.tax_amount_and_service_tax || 0).toFixed(1)}
          />
          <StatisticItem
              label="صافي مبيعات مكان (الرسوم والعمولة)"
              value={(sales.paid?.commission_amount_and_service_amount || 0).toFixed(1)}
          />

        </div>
      </div>
  );
};

export default MakanStatistics;
